import React from 'react'
import Layout from '../components/layout'
import JavascriptDeveloper from '../components/categories/javascript-developer'
import Seo from '../components/seo'

const JavascriptDeveloperPage = ({ location }) => {
  return (
    <Layout location={location}>
      <JavascriptDeveloper />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Javascript Developer Freelancers | Codestaff'
  const description =
    'Hire the best Javascript Developer freelancers at Codestaff. Get the top 1% of Java Developer professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default JavascriptDeveloperPage
