import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import styled from 'styled-components'
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta1 = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": {
    "@type": "Question",
    "name": "What Does A JavaScript Developer Do?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "A JavaScript Developer implements the front-end logic that defines how the visual elements of web applications behave. JavaScript Developers are also responsible for connecting the visual elements with the services on the back-end."
    }
  }
}

const faqMeta2 = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": {
    "@type": "Question",
    "name": "Why Hire a JavaScript Developer?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "A full-stack JavaScript Developer will ensure the development of your website' front-end and back-end because JavaScript allows a developer to write both front-end and back-end code. Codestaff javascript developers offers you the best javascript service at an affordable rate."
    }
  }
}

const JavascriptDeveloper = () => (
  <MainWrapper>
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
      <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
    </Helmet>
    <main>
      <section className="cx_catagories">
        <div className="container-fluid cx_top">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-10">
                <h2>
                  Hire top freelance<br /><span> Javascript Developers.</span>
                </h2>
                <p>
                  Codestaff is a marketplace for top Javascript Developers. Top
                  companies and start-ups choose Codestaff professional Javascript Developers
                  for their mission critical software projects.
                            </p>
                <Link to="/hire/" className="skills-banner-btn">
                  Hire a top Javascript Developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <CxSearch title='HIRE FREELANCE JAVASCRIPT DEVELOPERS' banner='/javascript-developer.png' bannerAlt='javascript developer banner' />
      </section>
      <HireAndApply />
      <TopTrendingSkills />
    </main>
  </MainWrapper>)

export default JavascriptDeveloper